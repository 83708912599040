import React from "react";
import { IconProps } from "../icon";

export const CheckDone_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C14.48 2 13.92 2 12.8 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 3.52 2 4.08 2 5.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C3.52 16 4.08 16 5.2 16H8m4-1l2 2 4.5-4.5M11.2 22h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 20.48 22 19.92 22 18.8v-7.6c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 8 19.92 8 18.8 8h-7.6c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C8 9.52 8 10.08 8 11.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C9.52 22 10.08 22 11.2 22z"
    />
  </svg>
);
