import React from "react";
import { IconProps } from "../icon";

export const Cryptocurrency_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.878 20.09a10 10 0 01-11.756 0M16.384 3.012a10 10 0 015.519 10.38m-19.806 0a10 10 0 015.52-10.38M17.5 12a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0z"
    />
  </svg>
);
