import React from "react";
import { IconProps } from "../icon";

export const Sale_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 9h.01M15 15h.01M16 8l-8 8m1.202 4.6a1.24 1.24 0 01.92.247l1.203.924c.398.305.951.305 1.348 0l1.248-.959c.234-.179.528-.258.82-.219l1.561.206c.497.065.976-.212 1.168-.675l.601-1.453c.112-.272.328-.488.6-.6l1.453-.601c.464-.191.74-.671.675-1.168l-.198-1.505a1.24 1.24 0 01.247-.92l.923-1.204a1.105 1.105 0 000-1.347l-.959-1.25a1.105 1.105 0 01-.219-.818l.206-1.562a1.108 1.108 0 00-.675-1.168l-1.453-.601a1.103 1.103 0 01-.6-.6l-.6-1.453a1.107 1.107 0 00-1.169-.675l-1.562.206a1.1 1.1 0 01-.817-.217l-1.25-.959a1.105 1.105 0 00-1.347 0l-1.249.959a1.115 1.115 0 01-.818.219L7.696 3.2a1.108 1.108 0 00-1.167.675l-.6 1.453a1.117 1.117 0 01-.6.6l-1.453.6c-.464.192-.74.671-.675 1.168l.206 1.562c.038.291-.041.585-.219.818l-.959 1.249a1.105 1.105 0 000 1.347l.959 1.25c.179.232.258.527.219.818L3.2 16.303c-.065.497.211.976.675 1.168l1.453.601c.272.112.488.328.6.6l.6 1.453c.192.464.672.74 1.169.675l1.504-.2zM9.5 9a.5.5 0 11-1 0 .5.5 0 011 0zm6 6a.5.5 0 11-1 0 .5.5 0 011 0z"
    />
  </svg>
);
