import React from "react";
import { IconProps } from "../icon";

export const Cryptocurrency_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 2l2 2m18-2l-2 2m2 18l-2-2M2 22l2-2m-2-4h1.5M8 2v1.5M22 8h-1.5M16 22v-1.5m2-4.5h3.5M16 2v4M2 8h4m2 14v-4m3.434-10.434l-3.868 3.868c-.198.198-.297.297-.334.412a.5.5 0 000 .309c.037.114.136.213.334.41l3.868 3.87c.198.197.297.296.412.333.1.033.208.033.309 0 .114-.037.213-.136.41-.334l3.87-3.868c.197-.198.296-.297.333-.412a.499.499 0 000-.309c-.037-.114-.136-.213-.334-.41l-3.868-3.87c-.198-.197-.297-.296-.412-.333a.5.5 0 00-.309 0c-.114.037-.213.136-.41.334z"
    />
  </svg>
);
