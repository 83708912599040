import React from "react";
import { IconProps } from "../icon";

export const Diamond_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 22h14M2.5 8h19M10 2L8 8l4 10.5L16 8l-2-6m-1.408 16.349l8.94-9.834c.166-.182.249-.274.282-.378a.5.5 0 00.006-.282c-.028-.106-.108-.2-.265-.39L17.24 2.288c-.088-.106-.132-.159-.186-.197a.5.5 0 00-.158-.074C16.832 2 16.763 2 16.626 2H7.375c-.138 0-.207 0-.27.017a.5.5 0 00-.158.074 1.08 1.08 0 00-.187.197L2.446 7.465c-.158.19-.237.284-.265.39a.5.5 0 00.006.282c.033.104.116.196.282.378l8.94 9.834c.205.226.307.338.428.38a.5.5 0 00.327 0c.12-.041.223-.154.428-.38z"
    />
  </svg>
);
