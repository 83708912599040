import React from "react";
import { IconProps } from "../icon";

export const CurrencyEuroIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 5.519a8.5 8.5 0 100 12.962M3 14h10M3 10h10"
    />
  </svg>
);
