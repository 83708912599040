import React from "react";
import { IconProps } from "../icon";

export const CheckVerified_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 12l2 2 4.5-4.5M7.334 3.819a3.832 3.832 0 002.18-.904 3.832 3.832 0 014.972 0c.613.523 1.376.84 2.18.904a3.832 3.832 0 013.515 3.515c.064.804.38 1.567.904 2.18a3.832 3.832 0 010 4.972 3.832 3.832 0 00-.904 2.18 3.832 3.832 0 01-3.515 3.515 3.832 3.832 0 00-2.18.904 3.832 3.832 0 01-4.972 0 3.832 3.832 0 00-2.18-.904 3.832 3.832 0 01-3.515-3.515 3.832 3.832 0 00-.904-2.18 3.832 3.832 0 010-4.972c.523-.613.84-1.376.904-2.18a3.832 3.832 0 013.515-3.515z"
    />
  </svg>
);
