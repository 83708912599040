import React from "react";
import { IconProps } from "../icon";

export const ActivityIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9 2a1 1 0 01.949.684L15 17.838l2.051-6.154A1 1 0 0118 11h4a1 1 0 110 2h-3.28l-2.771 8.316a1 1 0 01-1.898 0L9 6.162l-2.051 6.154A1 1 0 016 13H2a1 1 0 110-2h3.28L8.05 2.684A1 1 0 019 2z"
      clipRule="evenodd"
    />
  </svg>
);
