import React from "react";
import { IconProps } from "../icon";

export const ShoppingCart_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 14h13.136c1.013 0 1.52 0 1.922-.189a2 2 0 00.858-.768c.232-.38.288-.883.4-1.89l.585-5.265c.035-.307.052-.46.002-.58a.5.5 0 00-.22-.246C21.57 5 21.416 5 21.106 5H4.5M2 2h1.248c.265 0 .397 0 .5.05a.5.5 0 01.217.204c.057.1.065.232.082.496l.906 14.5c.017.264.025.396.082.496a.5.5 0 00.216.204c.104.05.236.05.5.05H19M7.5 21.5h.01m8.99 0h.01M8 21.5a.5.5 0 11-1 0 .5.5 0 011 0zm9 0a.5.5 0 11-1 0 .5.5 0 011 0z"
    />
  </svg>
);
