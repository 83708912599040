import React from "react";
import { IconProps } from "../icon";

export const ShoppingCart_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 2h1.306c.246 0 .37 0 .468.045a.5.5 0 01.213.185c.059.092.076.213.111.457L4.571 6m0 0l1.052 7.731c.134.982.2 1.472.435 1.841a2 2 0 00.853.745c.398.183.893.183 1.883.183h8.558c.942 0 1.414 0 1.799-.17a2 2 0 00.841-.696c.239-.346.327-.81.503-1.735l1.324-6.95c.062-.325.093-.488.048-.615a.5.5 0 00-.22-.266C21.532 6 21.366 6 21.034 6H4.571zM10 21a1 1 0 11-2 0 1 1 0 012 0zm8 0a1 1 0 11-2 0 1 1 0 012 0z"
    />
  </svg>
);
