import React from "react";
import { IconProps } from "../icon";

export const AnchorIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 8a3 3 0 100-6 3 3 0 000 6zm0 0v14m0 0A10 10 0 012 12h3m7 10a10 10 0 0010-10h-3"
    />
  </svg>
);
