import React from "react";
import { IconProps } from "../icon";

export const CoinsSwap_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 6l2-2m0 0L6 2m2 2H6a4 4 0 00-4 4m16 10l-2 2m0 0l2 2m-2-2h2a4 4 0 004-4m-8.583-2.583a6 6 0 10-2.834-2.834M14 16a6 6 0 11-12 0 6 6 0 0112 0z"
    />
  </svg>
);
