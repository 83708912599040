import React from "react";
import { IconProps } from "../icon";

export const Edit_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 20h9M3 20h1.675c.489 0 .733 0 .964-.055.204-.05.399-.13.578-.24.201-.123.374-.296.72-.642L19.5 6.5a2.121 2.121 0 00-3-3L3.937 16.063c-.346.346-.519.519-.642.72a2 2 0 00-.24.578c-.055.23-.055.475-.055.965V20z"
    />
  </svg>
);
