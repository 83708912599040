import React from "react";
import { IconProps } from "../icon";

export const Cryptocurrency_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.878 20.09a10 10 0 01-11.756 0M16.384 3.012a10 10 0 015.519 10.38m-19.806 0a10 10 0 015.52-10.38m3.817 3.553l-4.868 4.87c-.198.197-.297.296-.334.41a.5.5 0 000 .31c.037.113.136.212.334.41l4.868 4.87c.198.197.297.296.412.333a.5.5 0 00.309 0c.114-.037.213-.136.41-.334l4.87-4.869c.197-.198.296-.297.333-.41a.5.5 0 000-.31c-.037-.114-.136-.213-.334-.41l-4.868-4.87c-.198-.198-.297-.297-.412-.334a.5.5 0 00-.309 0c-.114.037-.213.136-.41.334z"
    />
  </svg>
);
