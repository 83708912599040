import React from "react";
import { IconProps } from "../icon";

export const BookmarkCheckIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 10.5l2 2L15.5 8M19 21V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C16.72 3 15.88 3 14.2 3H9.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C5 5.28 5 6.12 5 7.8V21l7-4 7 4z"
    />
  </svg>
);
