import React from "react";
import { IconProps } from "../icon";

export const CheckDoubleIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color || "#000"}
      d="M15.743 7.669a1 1 0 10-1.486-1.338l1.486 1.338zM6 17l-.707.707a1 1 0 001.45-.038L6 17zm-3.293-4.707a1 1 0 00-1.414 1.414l1.414-1.414zm20.036-4.624a1 1 0 10-1.486-1.338l1.486 1.338zM13 17l-.707.707a1 1 0 001.45-.038L13 17zm-2.793-4.207l-.707-.707L8.086 13.5l.707.707 1.414-1.414zm4.05-6.462l-9 10 1.486 1.338 9-10-1.486-1.338zm-7.55 9.962l-4-4-1.414 1.414 4 4 1.414-1.414zm14.55-9.962l-9 10 1.486 1.338 9-10-1.486-1.338zm-7.55 9.962l-3.5-3.5-1.414 1.414 3.5 3.5 1.414-1.414z"
    />
  </svg>
);
