import React from "react";
import { IconProps } from "../icon";

export const Scales_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.5 13h6m7 0h6M12 7v14m0-14a2.5 2.5 0 002.5-2.5M12 7a2.5 2.5 0 01-2.5-2.5M4 21h16M4 4.5h5.5m0 0a2.5 2.5 0 015 0m0 0H20M8.88 14.336C8.48 15.871 7.12 17 5.5 17c-1.618 0-2.98-1.13-3.38-2.664a1.383 1.383 0 01-.05-.438 2.882 2.882 0 01.105-.653c.08-.237.167-.371.34-.64L5.5 8l2.986 4.606c.173.268.26.402.34.639.05.145.107.5.106.653-.002.25-.018.313-.051.438zm13 0C21.48 15.871 20.12 17 18.5 17c-1.618 0-2.98-1.13-3.38-2.664-.033-.125-.05-.188-.05-.438-.002-.154.055-.508.105-.653.08-.237.167-.371.34-.64L18.5 8l2.986 4.606c.173.268.26.402.34.639.05.145.107.5.106.653-.002.25-.018.313-.051.438z"
    />
  </svg>
);
