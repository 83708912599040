import React from "react";
import { IconProps } from "../icon";

export const CurrencyEthereumIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 11l8 2 8-2M4 11l8-9m-8 9l8-2m8 2l-8-9m8 9l-8-2m0-7v7m-6.5 6l6.5 7 6.5-7-6.5 1.5L5.5 15z"
    />
  </svg>
);
