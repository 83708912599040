import React from "react";
import { IconProps } from "../icon";

export const Download_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 12l4 4m0 0l4-4m-4 4V6.8c0-1.39 0-2.086-.55-2.865-.366-.517-1.42-1.155-2.047-1.24-.945-.128-1.304.059-2.022.433A9.999 9.999 0 002 12c0 5.523 4.477 10 10 10s10-4.477 10-10a9.996 9.996 0 00-5-8.662"
    />
  </svg>
);
