import React from "react";
import { IconProps } from "../icon";

export const CurrencyBitcoinIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.5 2v2m0 16v2m4-20v2m0 16v2m-6-18H14a4 4 0 010 8H7.5 15a4 4 0 010 8H7.5m0-16h-2m2 0v16m0 0h-2"
    />
  </svg>
);
