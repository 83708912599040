import React from "react";
import { IconProps } from "../icon";

export const Sale_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 9h.01M15 15h.01M16 8l-8 8m9.901-11.001c.206.498.601.893 1.1 1.1l1.744.723a2.033 2.033 0 011.1 2.656l-.722 1.744a2.03 2.03 0 000 1.556l.722 1.744a2.033 2.033 0 01-1.1 2.656L19 17.901A2.033 2.033 0 0017.9 19l-.723 1.745a2.032 2.032 0 01-2.656 1.1l-1.744-.722a2.032 2.032 0 00-1.555 0l-1.745.723a2.033 2.033 0 01-2.654-1.1L6.1 19.001A2.033 2.033 0 005 17.9l-1.744-.723a2.033 2.033 0 01-1.1-2.654l.721-1.744a2.033 2.033 0 000-1.556l-.722-1.746a2.033 2.033 0 011.1-2.657L5 6.098c.498-.206.893-.6 1.1-1.098l.723-1.745a2.033 2.033 0 012.656-1.1l1.744.722a2.033 2.033 0 001.555-.001l1.746-.72a2.032 2.032 0 012.655 1.1l.723 1.746v-.003zM9.5 9a.5.5 0 11-1 0 .5.5 0 011 0zm6 6a.5.5 0 11-1 0 .5.5 0 011 0z"
    />
  </svg>
);
