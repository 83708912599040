import React from "react";
import { IconProps } from "../icon";

export const DownloadCloud_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 16.242A4.5 4.5 0 016.08 8.02a6.002 6.002 0 0111.84 0A4.5 4.5 0 0120 16.242M8 17l4 4m0 0l4-4m-4 4v-9"
    />
  </svg>
);
