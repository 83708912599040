import React from "react";
import { IconProps } from "../icon";

export const Wallet_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16.5 14h.01M3 5v14a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2H5a2 2 0 01-2-2zm0 0a2 2 0 012-2h12m0 11a.5.5 0 11-1 0 .5.5 0 011 0z"
    />
  </svg>
);
