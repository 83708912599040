import React from "react";
import { IconProps } from "../icon";

export const Building_08Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 21h18M6 18v-8m4 8v-8m4 8v-8m4 8v-8m2-3l-7.576-4.735c-.154-.096-.23-.144-.313-.163a.5.5 0 00-.222 0c-.082.019-.16.067-.313.163L4 7h16z"
    />
  </svg>
);
