import React from "react";
import { IconProps } from "../icon";

export const AtSignIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94M16 12a4 4 0 11-8 0 4 4 0 018 0z"
    />
  </svg>
);
