import React from "react";
import { IconProps } from "../icon";

export const AutosaveFailIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.5 10.5L12 13m0 0l-2.5 2.5M12 13l-2.5-2.5M12 13l2.5 2.5m-8 3.5a4.5 4.5 0 01-.42-8.98 6.002 6.002 0 0111.84 0A4.5 4.5 0 0117.5 19h-11z"
    />
  </svg>
);
