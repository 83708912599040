import React from "react";
import { IconProps } from "../icon";

export const Coins_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.938 15.938A7.001 7.001 0 0015 2a7.001 7.001 0 00-6.938 6.062M7.5 13L9 12v5.5m-1.5 0h3M16 15a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
);
