import React from "react";
import { IconProps } from "../icon";

export const CloudBlank_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.5 19a7.5 7.5 0 116.641-10.988A5.5 5.5 0 1116.5 19h-7z"
    />
  </svg>
);
