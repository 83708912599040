import React from "react";
import { IconProps } from "../icon";

export const CheckCircleBrokenIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3"
    />
  </svg>
);
