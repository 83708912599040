import React from "react";
import { IconProps } from "../icon";

export const Wallet_05Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 10H5m14 0l1.864 3.107c.418.696.627 1.044.735 1.42.096.331.134.678.113 1.023-.025.389-.153.774-.41 1.544l-.208.624c-.393 1.18-.59 1.77-.955 2.206a3 3 0 01-1.203.868c-.53.208-1.151.208-2.396.208H7.46c-1.244 0-1.866 0-2.396-.208a3 3 0 01-1.203-.868c-.365-.436-.562-1.026-.955-2.206l-.208-.624c-.257-.77-.385-1.155-.41-1.545a3 3 0 01.113-1.023c.108-.375.317-.723.735-1.42L5 10m14 0l.417-1.46c.198-.693.297-1.039.22-1.313a1 1 0 00-.436-.577c-.242-.15-.602-.15-1.322-.15H6.12c-.72 0-1.08 0-1.322.15a1 1 0 00-.435.577c-.078.274.02.62.219 1.313L5 10m7-3.5H8.464c-.52 0-1.02-.21-1.389-.586A2.019 2.019 0 016.5 4.5c0-.53.207-1.04.575-1.414a1.947 1.947 0 011.39-.586c2.75 0 3.535 4 3.535 4zm0 0h3.536c.52 0 1.02-.21 1.389-.586.368-.375.575-.884.575-1.414 0-.53-.207-1.04-.575-1.414a1.947 1.947 0 00-1.39-.586c-2.75 0-3.535 4-3.535 4z"
    />
  </svg>
);
