import React from "react";
import { IconProps } from "../icon";

export const Gift_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 6v16m0-16H8.464c-.52 0-1.02-.21-1.389-.586A2.019 2.019 0 016.5 4c0-.53.207-1.04.575-1.414A1.947 1.947 0 018.465 2C11.214 2 12 6 12 6zm0 0h3.536c.52 0 1.02-.21 1.389-.586.368-.375.575-.884.575-1.414 0-.53-.207-1.04-.575-1.414A1.947 1.947 0 0015.535 2C12.786 2 12 6 12 6zm8 5v7.8c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C18.48 22 17.92 22 16.8 22H7.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C4 20.48 4 19.92 4 18.8V11M2 7.6v1.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C2.76 11 3.04 11 3.6 11h16.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C22 10.24 22 9.96 22 9.4V7.6c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C21.24 6 20.96 6 20.4 6H3.6c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C2 6.76 2 7.04 2 7.6z"
    />
  </svg>
);
